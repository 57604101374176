.slider {
    position: relative;
    .slick-slide .image {
        padding: 0;
        height: 100%;

    }

    .slick-slide {
        position: relative;
        min-height: 170px;

        @include respond-to-min($tabletSize) {
            min-height: 350px;
        }
        & > div:not(.slide-content) {
            @include respond-to-max($desktopSize) {
                padding-top: 56.25%;
            }
            .slick-slide {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
            }
            .slide-content {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                img {
                    height: auto;
                    width: 100%;

                    &.lazy {
                        height: auto;
                        width: auto;
                    }

                    @include respond-to-min($desktopSize) {
                        object-fit: cover;
                        min-height: 486px;
                        height: 100%;
                    }
                }
            }
        }
    }

    .slick-dots {
        @include respond-to-min($desktopSize) {
            bottom: $gutter;
        }
        @include respond-to-max($desktopSize) {
            bottom: $gutter * 0.5;
        }

        li {
            margin: 0;
        }

        li button:before {
            color: $white;
            opacity: 1;
            font-size: 10px;
        }

        .slick-active {
            button:before {
                color: $primary-color;
            }
        }
    }

    .slick-arrow {
        display: none;
    }

    @include respond-to-min($desktopSize) {
        .info {
            min-height: 37.5rem;
        }

        .slick-track,
        .container-fluid,
        .slider,
        .slick-list,
        .base-slider,
        .slider-section,
        .out-story {
            height: 100%;
            max-height: 48.6rem;
        }

        .fill-height {
            .slick-track,
            .container-fluid,
            .slider,
            .slick-list,
            .base-slider,
            .slider-section,
            .out-story {
                max-height: initial;
            }
        }
    }

    @include respond-to-min($laptopSize) {
        .base-slider,
        .slider-section {
            width: calc(100% + #{$gutter});
            margin-left: -$gutter;
        }
    }
     @include respond-to-min($laptopSize) {
         .base-slider,
         .slider-section {
             --bs-gutter-x: 1.5rem;
             position: absolute;
             //width: calc((100vw - var(--full-width)) / 2 + var(--full-width) - 33.33333333% - #{$gutter * 0.5});
            //width: calc( (var(--full-width) - 33.333333%) + var(--full-width) / 4 - $gutter * 2);
            width: calc(var(--full-width) - 33.333333% + var(--full-width)/11 - 10rem);
             left: 2rem;
         }
     }

    @include respond-to-min($defaultDesktopSize) {
        .base-slider,
        .slider-section {
            width: calc(var(--full-width) - 33.333333% + var(--full-width) / 11 - 11rem);
            left: 1.2rem;
        }
    }

    @include respond-to-min($normalDesktopSize) {
        .base-slider,
        .slider-section {
            width: calc( (var(--full-width) - 33.333333%) + var(--full-width) / 4 - 10rem);
            left: calc(-1 * 2.68rem);
        }
    }
    @include respond-to-min-max($desktopSize, $desktopSize) {
        .base-slider,
        .slider-section {
            position: absolute;
            width: calc((100vw - var(--full-width)) / 2 + var(--full-width) - 33%);
            left: 0;
        }
    }
     @include respond-to-min-max(995px, $laptopSize) {
         .base-slider,
         .slider-section {
             position: absolute;
             width: calc( (var(--full-width) - 33.333333%) + var(--full-width) / 5 - 18rem);
             left: -1rem;
         }
     }
}

.info {
    @include respond-to-min($laptopSize) {
        padding-left: $gutter;
        margin-top: $gutter * 3.5;
    }
    @include respond-to-min-max($desktopSize, $laptopSize) {
        padding-left: $gutter;
    }
    @include respond-to-max($desktopSize) {
        padding: 0 $gutter;
        margin-top: $gutter;
    }

    h1,
    h2 {
        font-size: 42px;
        font-weight: 600;
    }
    .content {
        font-size: $font-size--medium;
        font-weight: 500;
    }
}
