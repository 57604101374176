.main-nav {
    & > li {
        position: relative;
        &:visited,
        &:focus,
        &:focus-visible{
            outline: 0;
            & > a {
                color: $black;
            }
        }
        &:hover {
            & > a {
                color: $black;
            }
            ul {
                display: block;
            }
        }
        div {
            position: relative;
            width: fit-content;
            a {
                white-space: nowrap;
            }
        }
    }
    .show ul {
        display: block;
    }
    ul {
        width: fit-content;
        z-index: 9999;
        @include respond-to-min($laptopSize) {
            position: absolute;
            background: #f7f5ee;
            padding: $gutter * 0.5 $gutter;
            left: -$gutter;
            display: none;

            &::before {
                content: none;
                display: block;
                width: calc(100% - 64px);
                height: 2px;
                background: #000;
                top: 0;
                position: absolute;
            }
        }
        @include respond-to-max($laptopSize) {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s ease-in-out;
        }
        li {
            width: fit-content;
            display: block;
            float: none;
            line-height: 2.8rem;
            &.active {
                a {
                    color: $black;
                }
            }
        }
    }

    .active > div > a,
    .active > a {
                color: $black;
    }

    .active.has-child .arrow::before {
        color: $black;
    }

    a {
        &:hover,
        &:focus,
        &:active
        {
            color: $black!important;
        }
    }
}

.pland-menu {
    margin-bottom: $gutter * 0.5;

    .search-control-wrapper {
        justify-content: right;
    }

    p {
        margin-bottom: 0;
        line-height: 2.8rem;
        white-space: nowrap;
    }
}

.pland-navbar .pland-search {
    @include respond-to-max($laptopSize) {
        display: none;
    }
}

body {
    @include respond-to-max($laptopSize) {
        background-size: 13rem;
    }
}

.pland-navbar {
    position: relative;
    @include respond-to-min($laptopSize) {
        padding-top: 20rem;
        & > a {
            img {
                height: 12.5rem;
            }
        }
        .search-area {
            align-items: center;
            display: flex;
            justify-content: right;
        }
    }

    @include respond-to-max($laptopSize) {
        padding-top: $gutter;
        padding-left: $gutter * 0.5;
        img {
            width: 20rem;
        }
    }

    @include respond-to-max($mobileIphoneSE) {
        img {
            width: 15rem;
        }

        .main-logo {
            img {
                width: 50%;
            }
        }
    }

    .close-btn {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 33px;
        top: 17px;
        background: transparent;
        border: none;
        font-size: 3.2rem;
        color: $white;
        @include respond-to-min($laptopSize) {
            display: none;
        }

        &::-webkit-file-upload-button {
            background: none;
        }
    }

    .p-icon {
        position: absolute;
        @include respond-to-min($laptopSize) {
            bottom: 0;
            right: -4.2rem;
            translate: 100% 10rem;
        }
        @include respond-to-max($laptopSize) {
            bottom: 5.4rem;
            right: 1.2rem;
            transform: translateX(50%);
            z-index: 1;
        }
    }
}

.main-nav-pland {
    float: left;
    @include respond-to-max($laptopSize) {
        position: absolute;
        z-index: 99;
        background: $primary-color;
        height: 100vh;
        top: 12rem;
        padding-right: $gutter;
        padding-top: $gutter;
        display: none;
        width: 100vw;
        left: $gutter * -0.25;
        z-index: 1;

        .main-nav {
            margin-left: 15px;
        }

        &.show {
            display: flex;
            z-index: 9;
        }
        a {
            color: $white;
        }
    }

    @include respond-to-min-max($desktopSize, $laptopSize) {
        left: calc((100vw - 96rem + 1.6rem) / -2);
    }

    @include respond-to-min-max($tabletSize, $desktopSize) {
        left: calc((100vw - 72rem + 1.6rem) / -2);
    }

    @include respond-to-min-max($maxMobileContainerSize, $tabletSize) {
        left: calc((100vw - 54rem + 1.6rem) / -2);
    }

    li {
        list-style: none;
        line-height: 2.8rem;
        @include respond-to-min($laptopSize) {
            float: left;
            width: 49%;
            display: inline-block;
            font-size: 1.4rem;
        }

        @include respond-to-min($defaultDesktopSize) {
            font-size: 1.6rem;
        }
        @include respond-to-max($laptopSize) {
            width: 100%;
            &.has-child {
                .arrow {
                    position: absolute;
                    transform: translateX(-120%);
                    padding-left: $gutter * 0.5;
                    cursor: pointer;
                    top: 3px;
                    &::before {
                        content: " > ";
                        width: 1.5rem;
                        display: block;
                        color: $white;
                        font-size: 2.5rem;
                    }
                }

                &.show {
                    .arrow::before {
                        transform: translateY(10%) translateX(-20%) rotate(90deg);
                    }
                    ul {
                        max-height: 25vh;
                    }
                }
                ul li:last-child {
                    margin-bottom: $gutter * 0.5;
                }
            }
        }
    }
}

.mobile-menu {
    @include respond-to-min($laptopSize) {
        display: none;
    }
    @include respond-to-max($laptopSize) {
        display: block;
        width: 4rem;
        position: absolute;
        right: 7.15rem;
        top: 16rem;
        img {
            width: 100%;
        }
    }

    @include respond-to-max($mobileIphoneSE) {
        right: 5rem;
        top: 14rem;
    }
}

.search-control-wrapper {
    display: flex;
    .input-wrapper {
        position: relative;
    }
}

.pland-navbar {
    .pland-menu {
        margin-top: 30px;
        .row {
            @include respond-to-min($desktopSize) {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                .col-lg-3,
                .col-lg-4{
                    width: initial;
                    flex-shrink: initial;
                }

                .search-area {
                    //padding-right: 0;
                }
                .offset-lg-1 {
                    margin-left: initial;
                }
            }
        }

        .main-nav-pland {
            .main-nav {
                > li {
                    position: relative;
                    @include respond-to-min($laptopSize) {
                        &:hover {
                            & > div {
                                &::after {
                                    content: " ";
                                    display: block;
                                    width: 100%;
                                    height: 2px;
                                    background: $black;
                                    bottom: 0;
                                    position: absolute;
                                }
                            }
                        }
                    }
                    &.active {
                      > div {
                        > a {
                            color: $black;
                        }
                      }
                    }
                }
            }
            li {
                @include respond-to-min($laptopSize) {
                    width: 50%;
                }

                a {
                    text-transform: capitalize;
                    font-size: 2.1rem;
                    line-height: 3.4rem;
                    color: $white;
                    @include respond-to-min($laptopSize) {
                        color:#8cc026;
                        font-size: 16px;
                        line-height: 19px;
                    }
                    &:hover{
                        color: $black;
                        opacity: 1;
                    }

                    &:active {
                        color: $black;
                        opacity: 1;
                    }
                    &:focus {
                        color: $black;
                        opacity: 1;
                    }
                    &:focus-visible {
                        color: $black;
                        opacity: 1;
                    }
                    &:focus-within {
                        color: $black;
                        opacity: 1;
                    }
                }
                ul {
                    li.active {
                        a {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}
