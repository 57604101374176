footer {
    li {
        list-style: none;
    }

    ul {
        display: flex;
        white-space: nowrap;
        flex-wrap: wrap;
        li {
            width: 33.33%;
        }
    }

    .logo {
        background: $black;
        img {
            width: 100%;
            // padding: $gutter * 0.5 $gutter;
            margin-bottom: $gutter * 0.5;
        }
    }

    .address {
        div {
            width: 70%;
            margin-left: auto;
            margin-right: 5%;
        }
    }

    .social_link {
        .text {
            ul {
                padding-left: $gutter * 0.5;
            }
        }

        .icon {
            ul {
                padding-left: $gutter * 0.5;
            }
        }
    }

    @include respond-to-max($laptopSize) {
        padding-bottom: $gutter;
        .logo {
            background: transparent;
            width: 30rem;
            margin: auto;
        }
        .footer-content-area {
            flex-direction: column;
            .first-block {
                order: 3;
                width: 37rem;
                margin: auto;
                li {
                    width: 50%;
                    text-align: center;
                }
            }
        }
        .contact-group {
            flex-direction: column;
            .address > div {
                margin: auto;
                width: 50%;
                text-align: center;
            }
        }

        .text {
            display: none;
        }

        .social_link  .icon {
            max-width: max-content;
            margin: $gutter auto;
            ul {
                padding-left: 0;
            }
            li {
                padding: 0 $gutter * 0.5;
            }
        }
    }

    .footer-navigation {
        .footer-navigation-content {
            @include respond-to-min($laptopSize) {
                padding-left: 21%;
            }
            ul {
                li {
                    width: 50%;
                }
            }
        }

    }
}
.copyright {
    font-size: 13px;
    font-weight: 400;
    text-align: right;
    a {
        font-weight: 600;
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &::after {
            content: ">";
            display: inline-block;
            margin-top: 2px;
            padding-left: 3px;

        }
    }

    &.on-mobile {
        display: block;
        text-align: center;
        @include respond-to-min($desktopSize) {
            display: none;
        }
    }

    &.on-desktop {
        display: none;
        @include respond-to-min($desktopSize) {
            display: block;
        }
    }
}