$font-path: "/themes/user/site/default/asset/fonts/HurmeGeometricSans1/";

@font-face {
    font-family: 'HurmeGeometricSans1';
    src: url('#{$font-path}HurmeGeometricSans1-SemiBold.woff2') format('woff2'), url('#{$font-path}HurmeGeometricSans1-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HurmeGeometricSans1';
    src: url('#{$font-path}HurmeGeometricSans1-Regular.woff2') format('woff2'), url('#{$font-path}HurmeGeometricSans1-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'HurmeGeometricSans1';
    src: url('#{$font-path}HurmeGeometricSans1-Thin.woff2') format('woff2'), url('#{$font-path}HurmeGeometricSans1-Thin.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'HurmeGeometricSans1';
    src: url('#{$font-path}HurmeGeometricSans1-Bold.woff2') format('woff2'), url('#{$font-path}HurmeGeometricSans1-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
