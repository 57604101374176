$remBase: 10px!default; // 1rem = 10px
:root {

    font-size: percentage(calc($remBase / 16px));
    --full-width: 0;
    @include respond-to-min($maxMobileContainerSize) {
        --full-width: 540px;
    }
    @include respond-to-min($tabletSize) {
        --full-width: 720px;
    }
    @include respond-to-min($desktopSize) {
        --full-width: 960px;
    }
    @include respond-to-min($laptopSize) {
        --full-width: 1140px;
    }
    @include respond-to-min($defaultDesktopSize) {
        --full-width: 1320px;
    }
}
::marker {
    font-weight: 400;
    //font-size: 12px;
    vertical-align: top;
    padding: 0;
    margin: 0;
}

html, body {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
}

body {
    --bs-body-font-size: #{$font-size--medium};
    font-family: "HurmeGeometricSans1";
    font-weight: 600;
    background-color: $mainBackground;

    >.content {


        @include respond-to-min($desktopSize) {
            margin-top: 1rem;
            position: relative;
            z-index: 1;
        }

    }
}

b, strong {
    font-weight: 600;
}
.hide, .display-none, [v-cloak] {
    display: none !important;
}
.hidden {
    &:not(.vue-init) {
        display: none;
    }
}
.content {
    ul {
        padding-left: 1rem;
        font-size: 1.6rem;
        //line-height: 2.1rem;
    }
}


a {
    text-decoration: none;
    color: $primary-color;
    transition: color 0.2s ease;
    &:hover {
        color: $primary-color--hover;
    }
}
.color-{
    &black {
        color:$black;
    }
}
.for- {
    &desktop {
        display: none;

        @include respond-to-min($desktopSize) {
            display: block;
        }
    }

    &mobile {
        display: block;
        @include respond-to-min($desktopSize) {
            display: none;
        }
    }
}

form.pland-search input[type=text] {
    padding: 10px;
    font-size: 1.8rem;
    border: none;
    float: left;
    width: 100%;
    background: #EDE9DA;
    height: 36px;
    //color: #000;


  +label.error {
    color: $error;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    display: block;
    font-size: 11px;
  }
}

form.pland-search button {
    float: left;
    width: 4.5rem;
    background: #EDE9DA;
    color: $white;
    border: none;
    border-left: none;
    cursor: pointer;
    height: 36px;
    transition: background 0.2s ease;
    &:hover {
        //background: $black;
    }
}


form.pland-search::after {
    content: "";
    clear: both;
    display: table;
}

.pland-search img {
    width: 20px;
}

.pland-select {
    font-size: $font-size--medium;
    font-weight: 600;
    background-color: $background;
    padding: $gutter * 0.5 $gutter;
    width: fit-content;
    border: none;
    border-radius: 0;
    padding-right: $gutter * 3;
    background-image: url(/themes/user/site/default/asset/img/common/arrow-down.png);
    background-repeat: no-repeat;
    background-position: right $gutter * 0.75 center;
    background-size: 2.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    max-width: 100%;
    outline: none;
    color: $black;
    option {
        font-family: "HurmeGeometricSans1", sans-serif;
        font-weight: 400;
    }
}

.team-pages {
    .ee-cform {
        .select {
            @extend .pland-select;
        }
    }
}



.pagination {
    ul {
        padding: 0;
        white-space: nowrap;
    }

    li {
        list-style: none;
        display: inline;
        text-transform: lowercase;
        font-weight: 600;
        &:nth-child(2){
            color: $primary-color;
            &::before {
                content: " / ";
            }
            &::after {
                content: " / ";
            }
        }
        &:nth-child(1) {
          span,a{
            &::before {
              content: " < ";
            }
          }
        }

        &:nth-child(3) {
          a, span{
            &::after {
              content: " > ";
            }
          }
        }
    }
}



.what-we-do {
    @include respond-to-max($desktopSize) {
        padding: $gutter;
    }

    .desc-content{
        h2{
            font-size: 32px;
            font-weight: 600;
        }
        p{
            font-weight: 400;
        }
        ul{
            list-style: auto;
            font-weight: 400;
            line-height: inherit;
            padding-left: 20px;
            font-size: inherit;
            strong{
                font-weight: 600;
            }
        }
    }

    .cert-label {
        font-size: 2.0rem;
        line-height: 2.2rem;
    }

    .project-info {
        font-size: $font-size--medium;
        margin-bottom: $gutter * 0.75;
        .location {
            font-weight: 500;
        }
    }

    .project-cert {
        padding: $gutter * 1;
        background: $primary-color;
        ul {
            color: $white;
            font-weight: normal;
            padding-left: 1.25rem;
            font-size: 1.6rem;
            line-height: inherit;
        }
    }

    .content-area {
        padding: $gutter;
        margin-bottom: $gutter * 2;
        &-detail {
            font-size: $font-size--medium;
            font-weight: 500;

            @include respond-to-min($desktopSize) {
                padding-left: $gutter;
            }
            @include respond-to-max($desktopSize) {
                margin-top: $gutter;
            }
        }

        .quote {
            background: $primary-color;
            padding: $gutter;
            font-weight: 500;

            @include respond-to-min($desktopSize) {
                font-size: 3.3rem;
            }
            @include respond-to-max($desktopSize) {
                font-size: 2.8rem;
            }
        }

        a {
            font-weight: 600;
        }

        li {
            list-style: none;
        }

        ul {
            padding: 0;
        }

        .related-project {
            margin-top: $gutter * 0.5;
        }

        .pland-select {
            margin-bottom: $gutter * 0.5;
        }
    }

    &.careers {
        .title {
            font-size: 3.2rem;
            margin-bottom: $gutter * 0.5;
            font-weight: 600;
        }
        .quote {
            font-weight: 600;
            font-size: 3.1rem;
            span {
                color: $white;
            }
        }
        .pland-select {
            margin-bottom: $gutter * 3;
        }
    }
}

.what-we-do-container {
    @include respond-to-max($desktopSize) {
        margin-top: $gutter;
    }
}

label.error {
  color: $error;
  font-size: 12px;
}
input[type=text],
input[type=email],
input[type=password],
textarea
{
  outline: none;
  &.error {
    border: 1px solid $error;
  }
}




.team-individual{
    display: inline-block;
    width: 240px;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-left: 0px;
    //height: 300px;
    @media screen and (max-width: 480px) {
      float: none;
        width: 100%;
        margin-right: 0;
        height: auto;
        padding: 8px;
    }
}

.team-individual .photo-part{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center 0px;
    position: relative;
    background-size: cover;

    img {
        width: 100%;
    }
}

.team-individual .name-design .name,
.team-individual .name-design .name a,
.team-individual .name-design .name a:hover{
    text-align: left;
    font-size: $font-size--medium;
    color: #000;
    margin-bottom: 0px;
    margin-top: $gutter * 0.25;
}

.team-individual .name-design .des,
.team-individual .name-design .des a,
.team-individual .name-design .des a:hover{
    text-align: left;
    font-size: $font-size--normal;

}




.photo-part:hover .hover-text{
    opacity: 1;
}

.profiles {
    @include respond-to-min($desktopSize) {
        margin-bottom: $gutter * 3;
        display: flex;
        flex-wrap: wrap;
    }
    @include respond-to-max($desktopSize) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .team-individual {
        @include respond-to-min($desktopSize) {
            margin-bottom: $gutter;
        }
        @include respond-to-max($mobileBasic) {
            padding: 10px;
        }
        @include respond-to-max($desktopSize) {
            height: auto;
            flex: 0 50%;
            flex-basis: 50%;
            margin-right: 0;
            padding: 20px;
        }
    }

    .profile-cover {
        overflow: hidden;
        .hover-text {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            overflow: auto;
            color: #fff;
            background-color: #8dc126;
            height: 100%;
            text-align: left;
            padding: $gutter * 0.75 $gutter * 0.5;
            font-weight: normal;
            font-size: 2.4rem;
            line-height: 2.8rem;
            opacity: 0;
            transition: all .5s;
            cursor: pointer;

            p {
                &:nth-child(1) {
                    &::before {
                        content: "“ ";
                    }
                }

                &:nth-last-child(1) {
                    &::after {
                        content: " ”";
                    }
                }
            }
        }

        .photo-part {
            transition: all .2s ease-in-out;
        }
        &:hover {
            .hover-text{
                opacity: 1;
            }

            .photo-part {
                transform: scale(1.1);
            }
        }
    &.position-relative{
        span{
          position: absolute;
        }
    }
    }
}

.team-pages {
    @include respond-to-min($desktopSize) {
        margin-top: $gutter * 2;
    }
    @include respond-to-max($desktopSize) {
        .pland-select {
            width: calc(100% - #{$gutter * 0.625 * 2});
            margin: 0 $gutter * 0.625;
        }
    }

    .dropdown.all {

        * {
            @include respond-to-min($desktopSize) {
                font-size: $font-size--medium;
            }
            @include respond-to-max($desktopSize) {
                font-size: $font-size--normal;
            }
        }

        .dropdown-header {
            color: $black;
            padding: 0;
            @include respond-to-min($desktopSize) {
                margin-top: $gutter;
                margin-bottom: $gutter * 0.25;
            }
            @include respond-to-max($desktopSize) {
                margin-top: $gutter * 0.5;
            }
            a {
                color: $black;
                padding: 0;
            }
        }

        .dropdown-item-content {
            margin-bottom: $gutter * 0.125;
            a.selected {
                color: $black;
            }
        }

        .dropdown-menu,
        .dropdown-toggle {
            background: $background;
            @include respond-to-min($desktopSize) {
                width: 42.5rem;
            }
            @include respond-to-max($desktopSize) {
                width: 100%;
            }
        }

        .dropdown-toggle {
            border: none;
            text-align: left;
            color: $black;
            outline: none;
            @include respond-to-min($desktopSize) {
                padding: $gutter * 0.5 $gutter;
            }
            @include respond-to-max($desktopSize) {
                padding: $gutter * 0.25 $gutter * 0.5;
            }
            font-weight: 600;
            &::after {
                display: none;
            }
            img {
                float: right;
                height: 2.7rem;
                width: 2.5rem;
                object-fit: contain;
                @include respond-to-min($desktopSize) {
                    margin-right: $gutter * -0.5;
                }
                @include respond-to-max($desktopSize) {
                    margin-right: $gutter * -0.25;
                }
            }
        }

        .dropdown-menu {
            border: none;
            border-radius: 0;
            margin-top: -3px !important;
            @include respond-to-min($desktopSize) {
                padding: $gutter;
                padding-top: 0.5rem;
            }
            @include respond-to-max($desktopSize) {
                padding: $gutter * 0.5;
                padding-top: 0.5rem;
            }

            &.project-dropdown-menu {
                .parent-item {

                }

            }

            .dropdown-item-content {
                a {
                    &.active {
                        color: #000;
                    }
                }
            }
        }
    }
}

.main-container {
    @include respond-to-max($desktopSize) {
        padding: 0;
    }
}

.input-file {
    visibility: visible;
    display: inline-block;
    border: none;
    box-shadow: none;
    border: 1px solid #9ac455;
    border-radius: 0;
    padding: 0.75rem 2rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    background: #9ac455;
    font-size: 1.4rem;
    &:hover {
        color: #000;
    }

}
input[type=file],
#form-input-resume{
    position: relative;
    cursor: pointer;
    color: #9ac455;
    -moz-appearance:initial;
    appearance: auto;

    &::file-selector-button {
        @extend .input-file;
    }
    &::-webkit-file-upload-button {
        //@extend .input-file;
    }

    &::-ms-browse {
        //@extend .input-file;
        //display: table;
    }

    &:hover {
        color: #000;
    }

}
#rc-anchor-container{
     background: #F7F5EF!important;
}


.slider .slick-slide>div:not(.slide-content) .slide-content .eeFrontEdit  img{
    height: auto !important;
    min-height: auto !important;
}

.eeFrontEdit img{
    width: 24px !important;
    height: 24px !important;
}

#spin-loading {
    z-index: 9;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .sprinloading-content {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        img {
            width: 70px;
            height: 70px;
        }
    }
}
.photo-caption {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #8CC026;
    padding-left: 30px;
    margin-top: 30px;
}

.slick-next {
    right: 12px;
}
.slick-prev {
    left: 12px;
    z-index: 1;
}